import React, { useRef, useState, useEffect } from "react";
import { start } from "repl";

interface PhotoSenderModalProps {
    isOpen: boolean;
    onClose: () => void;
    onVerificationSuccess: () => Promise<void>;
}

const PhotoSenderModal: React.FC<PhotoSenderModalProps> = ({ isOpen, onClose, onVerificationSuccess }) => {
    const [isSending, setIsSending] = useState(false);
    const socketRef = useRef<WebSocket | null>(null);
    const [photoQueue, setPhotoQueue] = useState<File[]>([]);
    const [serverResponses, setServerResponses] = useState<string[]>([]);
    const videoRef = useRef<HTMLVideoElement>(null);
    const [circleSizeX, setCircleSizeX] = useState(80);
    const [circleSizeY, setCircleSizeY] = useState(110);
    const modalRef = useRef<HTMLDivElement>(null);
    const [timeoutOccurred, setTimeoutOccurred] = useState(false);
    const [navigationMessage, setNavigationMessage] = useState("Смотрите прямо в камеру"); 

    useEffect(() => {
        let timeoutId: NodeJS.Timeout | null = null;
    
        const startTimeout = () => {
            timeoutId = setTimeout(() => {
                setNavigationMessage("Faces do not match");
                setTimeoutOccurred(true);
            }, 70000); // Adjust timeout duration as needed
        };
    
        if (isOpen) {
            const token = localStorage.getItem("token"); // Replace 'authToken' with your token's key
            const wsUrl = `${process.env.REACT_APP_API_BASE_URL_WS}/ws/words/?token=${token}`;
    
            socketRef.current = new WebSocket(wsUrl);
    
            socketRef.current.onopen = () => {
                console.log("WebSocket connected");
    
                startTimeout();
            };

            socketRef.current.onclose = () => {
                console.log("WebSocket disconnected");
                if (timeoutId) clearTimeout(timeoutId);
            };
    
            socketRef.current.onerror = (error) => console.error("WebSocket error:", error);
    
            socketRef.current.onmessage = async (event) => {
                const data = JSON.parse(event.data);
                setServerResponses((prev) => [data, ...prev]);
    
                if (timeoutId) clearTimeout(timeoutId);
                startTimeout();
    
                if (data.status && data.stage === 2) {
                    toggleCircleSize();
                    setNavigationMessage("Первый этап пройден. Пожалуйста, подойдите ближе для дальнейшей верификации.");
                }
    
                if (data.status && data.stage === 3) {
                    setNavigationMessage("Face ID успешно пройден. Пожалуйста, подождите.");
                    socketRef.current?.close();
                    if (timeoutId) clearTimeout(timeoutId);
                    await onVerificationSuccess();
                    onClose();
                }
            };
        }
    
        return () => {
            if (timeoutId) clearTimeout(timeoutId);
            socketRef.current?.close();
        };
    }, [isOpen, onClose, onVerificationSuccess]);
    

    const tryAgain = () => {
        setNavigationMessage("Смотрите прямо в камеру");
        setTimeoutOccurred(false);
        setPhotoQueue([]);
        setServerResponses([]);
        startWebcam();
    };
    
    const toggleCircleSize = () => {
        setCircleSizeY((prevSize) => (prevSize === 110 ? 140 : 110));
        setCircleSizeX((prevSize) => (prevSize === 80 ? 110 : 80));
    };

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
                onClose();
            }
        };

        if (isOpen) {
            document.addEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isOpen, onClose]);

    useEffect(() => {
        const handleEscapeKey = (event: KeyboardEvent) => {
            if (event.key === 'Escape') {
                onClose();
            }
        };

        if (isOpen) {
            document.addEventListener('keydown', handleEscapeKey);
        }

        return () => {
            document.removeEventListener('keydown', handleEscapeKey);
        };
    }, [isOpen, onClose]);

    const startWebcam = async () => {
        try {
            const stream = await navigator.mediaDevices.getUserMedia({
                video: {
                    width: 640,
                    height: 480,
                    facingMode: "user"
                }
            });
            
            if (videoRef.current) {
                videoRef.current.srcObject = stream;
            }
        } catch (error) {
            console.error("Error accessing webcam:", error);
            alert("Unable to access webcam. Please check permissions and try again.");
        }
    };

    useEffect(() => {
        if (isOpen) {
            socketRef.current = new WebSocket(`${process.env.REACT_APP_API_BASE_URL_WS}/ws/words/?token=${localStorage.getItem("token")}`);

            socketRef.current.onopen = () => console.log("WebSocket connected");
            socketRef.current.onclose = () => console.log("WebSocket disconnected");
            socketRef.current.onerror = (error) => console.error("WebSocket error:", error);

            socketRef.current.onmessage = async (event) => {
                const data = JSON.parse(event.data);
                setServerResponses((prev) => [data, ...prev]);
                console.log(data.stage);
                console.log(data.status);
                console.log(data.status);

                if(data.status && data.stage === 2 ){
                    toggleCircleSize();
                    setNavigationMessage("Первый этап пройден. Пожалуйста, подойдите ближе для дальнейшей верификации.");   
                }
                if(data.status && data.stage === 3 ){
                    setNavigationMessage("Face ID успешно пройден. Пожалуйста, подождите.");
                    socketRef.current?.close();
                    await onVerificationSuccess();
                    onClose();
                }
                
            };
        }

        return () => {
            socketRef.current?.close();
        };
    }, [isOpen]);

    const capturePhoto = () => {
        if (videoRef.current) {
            const canvas = document.createElement('canvas');
            canvas.width = videoRef.current.videoWidth;
            canvas.height = videoRef.current.videoHeight;
            const ctx = canvas.getContext('2d');
            
            if (ctx) {
                ctx.drawImage(videoRef.current, 0, 0);
                canvas.toBlob((blob) => {
                    if (blob) {
                        const file = new File([blob], `capture_${Date.now()}.jpg`, { type: 'image/jpeg' });
                        setPhotoQueue(prev => [...prev, file]);
                    }
                }, 'image/jpeg');
            }
        }
    };

    useEffect(() => {
        if (isSending) {
            const interval = setInterval(capturePhoto, 1200);
            return () => clearInterval(interval);
        }
    }, [isSending]);

    useEffect(() => {
        const sendPhotos = async () => {
            if (socketRef.current?.readyState === WebSocket.OPEN && photoQueue.length > 0) {
                const photo = photoQueue[0];
                if (photo) {
                    const reader = new FileReader();
                    reader.onload = () => {
                        if (reader.result instanceof ArrayBuffer && socketRef.current) {
                            socketRef.current.send(reader.result);
                            setPhotoQueue(prev => prev.slice(1));
                        }
                    };
                    reader.readAsArrayBuffer(photo);
                }
            }
        };

        const interval = setInterval(sendPhotos, 1000);
        return () => clearInterval(interval);
    }, [photoQueue]);

    const startSending = () => {
        setIsSending(true);
        setPhotoQueue([]);
        setServerResponses([]);
        if (isOpen) {
            startWebcam();
        }
        return () => {
            if (videoRef.current?.srcObject instanceof MediaStream) {
                videoRef.current.srcObject.getTracks().forEach(track => track.stop());
            }
        };
    };
    const stopSending = () => {
        setIsSending(false);
        setPhotoQueue([]);
        setServerResponses([]);
    }

    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
            <div 
                ref={modalRef}
                className="bg-white rounded-lg shadow-xl w-full max-w-4xl mx-4 overflow-hidden"
            >
                <div className="p-6">
                    <div className="flex justify-between items-center mb-4">
                        <h2 className="text-2xl font-bold text-gray-800">Face ID</h2>
                        <button
                            onClick={onClose}
                            className="text-gray-500 hover:text-gray-700 transition-colors"
                        >
                            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                            </svg>
                        </button>
                    </div>

                    <div className="relative aspect-video w-full mb-6">
                        <video
                            ref={videoRef}
                            autoPlay
                            playsInline
                            className="w-full h-full object-cover rounded-lg"
                        />

                        <svg
                            width="100%"
                            height="100%"
                            viewBox="0 0 640 480"
                            preserveAspectRatio="xMidYMid slice"
                            className="absolute top-0 left-0 w-full h-full"
                        >
                            <defs>
                                <mask id="holeMask">
                                    <rect width="100%" height="100%" fill="white" />
                                    <ellipse
                                               cx="320"
                                               cy="240"
                                               rx={circleSizeX}
                                               ry={circleSizeY}
                                               fill="black"
                                           />
                                </mask>
                            </defs>
                            <rect
                                width="100%"
                                height="100%"
                                fill="rgba(255, 255, 255, 0.8)"
                                mask="url(#holeMask)"
                            />
                        </svg>
                    </div>

                    <div className="flex flex-col gap-4">
                        <div className="flex justify-center gap-4">
                            <button
                                onClick={timeoutOccurred ? tryAgain : (isSending ? stopSending : startSending)}
                                className={`px-4 py-2 rounded-full transition-all duration-300 ${
                                    isSending
                                        ? "bg-red-500 hover:bg-red-700 text-white"
                                        : "bg-green-500 hover:bg-green-700 text-white"
                                }`}
                            >
                                {timeoutOccurred
        ? "Try Again"
        : isSending
        ? "Cancel"
        : "Start"}
                                
                    
                            </button>
                        </div>

<div className="text-center text-sm text-gray-600">
                            <p>
                                {navigationMessage}
                            </p>
                        </div>
                        {/* <div className="text-center text-sm text-gray-600">
                            <p>
                                <span className="font-bold">{photoQueue.length}</span> photos in queue
                            </p>
                        </div> */}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PhotoSenderModal;