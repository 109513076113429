import React, { useEffect } from "react";
import Card from "components/card";
import { MdChevronRight, MdChevronLeft } from "react-icons/md";
import { useAuth } from "context/AuthContext";
import axios from "axios";
import Modal from "./Modal";
import { useState } from "react";
import { RowObj, UserRole } from "./types";
import { useTranslation } from "react-i18next";
import {
  PaginationState,
  createColumnHelper,
  useReactTable,
  ColumnFiltersState,
  getCoreRowModel,
  getFilteredRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFacetedMinMaxValues,
  getPaginationRowModel,
  getSortedRowModel,
  flexRender,
} from "@tanstack/react-table";

function CheckTable(props: { tableData: any }) {
  const { t } = useTranslation();
  const { tableData } = props;
  const { user } = useAuth();

  // State declarations
  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>([]);
  const [globalFilter, setGlobalFilter] = React.useState("");
  const [comment, setComment] = React.useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedRequestId, setSelectedRequestId] = useState<number | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [ctrl, setctrl] = useState("");
  const [pendingStatus, setPendingStatus] = useState<number | null>(null);
  const [selectedPaymentRequests, setSelectedPaymentRequests] = useState<RowObj[]>([]);

  // Pagination state
  const [{ pageIndex, pageSize }, setPagination] = React.useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });

  const pagination = React.useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize]
  );

  // Handle row selection
  const handleRowSelection = (row: RowObj, isSelected: boolean) => {
    setSelectedPaymentRequests(prev => {
      if (isSelected) {
        return [...prev, row];
      } else {
        return prev.filter(item => item.id !== row.id);
      }
    });
  };

  const handleVerificationStatusCol = (code: string) =>{
    console.log(code=="2");
    console.log(code)
    if(code== "1"){
      return t('approvalWait');
    }
    if (code == "2"){
      return t('approvedWaitConfirmation');
    }
    if (code == "3"){
      return t('notApproved');
    }
    if (code == "4"){
      return t('confirmed');
    }
    if (code == "5"){
      return t('notConfirmed');
    }
    if (code == "6"){
      return t('paid');
    }
    return code
  }
  // Table columns definition
  const columns = React.useMemo(() => {
    const columnHelper = createColumnHelper<RowObj>();
    return [
      columnHelper.accessor("select", {
        id: "select",
        header: () => (
          <p className="text-sm font-bold text-gray-600">{t('select')}</p>
        ),
        cell: (info) => {
          const row = info.row.original;
          const isSelected = selectedPaymentRequests.some(item => item.id === row.id);
          return (
            <input
              type="checkbox"
              checked={isSelected}
              onChange={(e) => handleRowSelection(row, e.target.checked)}
              className="text-md"
            />
          );
        },
      }),
      columnHelper.accessor("payment_date", {
        id: "payment_date",
        header: () => (
          <p className="text-sm font-bold text-gray-600">{t('paymentDate')}</p>
        ),
        cell: (info) => (
          <p className="text-sm font-bold text-navy-700">{info.getValue()}</p>
        ),
      }),
      columnHelper.accessor("payment_amount", {
        id: "payment_amount",
        header: () => (
          <p className="text-sm font-bold text-gray-600">{t('paymentAmount')}</p>
        ),
        cell: (info) => (
          <p className="text-sm font-bold text-navy-700">
            {Number(info.getValue()).toLocaleString()}₸
          </p>
        ),
      }),
      columnHelper.accessor("payment_purpose", {
        id: "payment_purpose",
        header: () => (
          <p className="text-sm font-bold text-gray-600">{t('paymentPurpose')}</p>
        ),
        cell: (info) => (
          <p className="text-sm font-bold text-navy-700">{info.getValue()}</p>
        ),
      }),
      columnHelper.accessor("verification_status", {
        id: "verification_status",
        header: () => (
          <p className="text-sm font-bold text-gray-600">{t('paymentStatus')}</p>
        ),
        cell: (info) => (
          <div className="flex items-center">
            <p className="text-sm font-bold text-navy-700">{handleVerificationStatusCol(info.getValue())}</p>
          </div>
        ),
      }),
      columnHelper.accessor("approved_by", {
        id: "approved_by",
        header: () => (
          <p className="text-sm font-bold text-gray-600">{t('approved_by')}</p>
        ),
        cell: (info) => (
          <p className="text-sm text-navy-700">
  {info.getValue()?.first_name || info.getValue()?.last_name 
    ? `${info.getValue()?.first_name || ''} ${info.getValue()?.last_name || ''}`.trim()
    : t('notYetApproved')}
</p>

        
        ),
      }),
      columnHelper.accessor("confirmed_by", {
        id: "confirmed_by",
        header: () => (
          <p className="text-sm font-bold text-gray-600">{t('confirmed_by')}</p>
        ),
        cell: (info) => (
          <p className="text-sm text-navy-700">
  {info.getValue()?.first_name || info.getValue()?.last_name 
    ? `${info.getValue()?.first_name || ''} ${info.getValue()?.last_name || ''}`.trim()
    : t('notYetConfirmed')}
</p>


        ),
      }),
    ];
  }, [selectedPaymentRequests]);

  // OTP and Action Handlers
  const requestOTP = async () => {
    try {
      await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/api/otp/`,
        { action: 'send',
          token: localStorage.getItem('token')
         }
      );
    } catch (error) {
      console.error('Failed to request OTP:', error);
      throw error;
    }
  };

  const verifyOTP = async (otp: string) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/api/otp/`,
        {
          token: localStorage.getItem('token'),
          action: 'verify',
          otp: parseInt(otp)
        }
      );
      console.log(response.status === 200)
      return response.status === 200;
    } catch (error) {
      console.error('Failed to verify OTP:', error);
      throw error;
    }
  };

  const handleBatchAction = async (action: string, newStatus: number) => {
    if (selectedPaymentRequests.length === 0) return;
  
    setIsLoading(true);
    try {
      if (action === "reject" && newStatus === 3) {
        setctrl('reason');
        setIsModalOpen(true);
        setPendingStatus(newStatus);
      } else if (action === "decline" && newStatus === 6) {
        setctrl('reason');
        setIsModalOpen(true);
        setPendingStatus(newStatus);
      } else {
        await requestOTP();
        setPendingStatus(newStatus);
        setctrl('otp');
        setIsModalOpen(true);
      }
    } catch (error) {
      console.error(`Failed to process batch action:`, error);
    } finally {
      setIsLoading(false);
    }
  };
  
  const handleReasonSubmit = async (reason: string) => {
    setComment(reason);
    try {
      await requestOTP();
    } catch (error) {
      console.error("Failed to request OTP:", error);
      setIsModalOpen(false);
    }
  };
  
  const handleOtpSubmit = async (otp: string) => {
    setIsLoading(true);
    try {
      const isValid = await verifyOTP(otp);
      console.log("pending?" ,pendingStatus)
      if (isValid && pendingStatus) {
        const paymentRequests = selectedPaymentRequests.map(request => ({
          id: request.id,
          verification_status: pendingStatus,
          comment: ctrl === 'reason' ? comment : "default"
        }));
  
        await axios.put(
          `${process.env.REACT_APP_API_BASE_URL}/api/bulk-update-payment-requests/`,
          {
            token: localStorage.getItem('token'),
            payment_requests: paymentRequests
          }
        );
        
        setIsModalOpen(false);
        window.location.reload();
      } else {
        throw new Error('OTP verification failed');
      }
    } catch (error) {
      console.error("Failed OTP verification:", error);
    } finally {
      setIsLoading(false);
      setPendingStatus(null);
    }
  };

  // Batch Action Buttons Component
  const BatchActionButtons = () => {
    if (!user?.role || selectedPaymentRequests.length === 0) return null;

    switch (user.role as UserRole) {
      case "director":
        return (
          <div className="flex gap-2 mt-4">
            <button
              onClick={() => handleBatchAction("approve", 2)}
              className="px-4 py-2 bg-green-500 text-white rounded-md hover:bg-green-600"
              disabled={isLoading}
            >
              {isLoading ? "Processing..." : `Approve (${selectedPaymentRequests.length})`}
            </button>
            <button
              onClick={() => handleBatchAction("reject", 3)}
              className="px-4 py-2 bg-red-500 text-white rounded-md hover:bg-red-600"
              disabled={isLoading}
            >
              {isLoading ? "Processing..." : `Reject (${selectedPaymentRequests.length})`}
            </button>
          </div>
        );
      case "shareholder":
        return (
          <div className="flex gap-2 mt-4">
            <button
              onClick={() => handleBatchAction("verify", 5)}
              className="px-4 py-2 bg-green-500 text-white rounded-md hover:bg-green-600"
              disabled={isLoading}
            >
              {isLoading ? "Processing..." : `Verify (${selectedPaymentRequests.length})`}
            </button>
            <button
              onClick={() => handleBatchAction("decline", 6)}
              className="px-4 py-2 bg-red-500 text-white rounded-md hover:bg-red-600"
              disabled={isLoading}
            >
              {isLoading ? "Processing..." : `Decline (${selectedPaymentRequests.length})`}
            </button>
          </div>
        );
      default:
        return null;
    }
  };

  // Table configuration
  const table = useReactTable({
    data: tableData,
    columns,
    state: {
      columnFilters,
      globalFilter,
      pagination,
    },
    onPaginationChange: setPagination,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
  });
  return (
    <Card extra={"w-full h-full sm:overflow-auto px-6 py-6"}>
      <Modal
        isOpen={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
          setPendingStatus(null);
        }}
        onSubmitOtp={handleOtpSubmit}
        ctrl={ctrl}
        onSubmitReason={handleReasonSubmit}
      />
      <header className="relative flex items-center justify-between pt-4">
        <div className="flex items-center gap-2">
          <div className="flex h-10 items-center rounded-full bg-white p-3">
            <input
              type="text"
              placeholder={t('search')}
              className="block w-full rounded-full text-sm font-medium text-gray-600 outline-none placeholder:text-gray-400 dark:bg-white dark:text-navy-700 lg:w-full"
              value={globalFilter ?? ""}
              onChange={(e) => setGlobalFilter(e.target.value)}
            />
          </div>
        </div>
      </header>

      <div className="mt-8 overflow-x-auto">
        <table className="w-full">
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id} className="!border-px !border-gray-400">
                {headerGroup.headers.map((header) => (
                  <th
                    key={header.id}
                    colSpan={header.colSpan}
                    className="cursor-pointer border-b-[1px] border-gray-200 pt-4 pb-2 pr-4 text-start"
                  >
                    {header.isPlaceholder ? null : (
                      <div>
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                      </div>
                    )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map((row) => (
              <tr key={row.id}>
                {row.getVisibleCells().map((cell) => (
                  <td key={cell.id} className="min-w-[150px] border-white/0 py-3 pr-4">
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Batch Action Buttons */}
      <BatchActionButtons />
      <div className="mt-2 flex flex-col md:flex-row items-center justify-between space-y-4 md:space-y-0">
        <div className="flex items-center gap-2">
          <button
            onClick={() => table.previousPage()}
            disabled={!table.getCanPreviousPage()}
            className="flex items-center gap-2 rounded-lg bg-gray-100 px-3 py-2 text-xs sm:text-sm text-gray-600 transition-colors hover:bg-gray-200 disabled:opacity-50"
          >
            <MdChevronLeft className="h-4 w-4 sm:h-5 sm:w-5" />
            {t('back')}
          </button>

          <button
            onClick={() => table.nextPage()}
            disabled={!table.getCanNextPage()}
            className="flex items-center gap-2 rounded-lg bg-gray-100 px-3 py-2 text-xs sm:text-sm text-gray-600 transition-colors hover:bg-gray-200 disabled:opacity-50"
          >
            {t('next')}
            <MdChevronRight className="h-4 w-4 sm:h-5 sm:w-5" />
          </button>
        </div>

        <div className="flex flex-wrap items-center gap-2 justify-center md:justify-end">
          <span className="flex items-center gap-1 text-xs sm:text-sm">
            <div>{t('page')}</div>
            <strong>
              {table.getState().pagination.pageIndex + 1} of{" "}
              {table.getPageCount()}
            </strong>
          </span>
          <span className="flex items-center gap-1 text-xs sm:text-sm">
          {t('page')}
            <input
              type="number"
              defaultValue={table.getState().pagination.pageIndex + 1}
              onChange={(e) => {
                const page = e.target.value ? Number(e.target.value) - 1 : 0;
                table.setPageIndex(page);
              }}
              className="w-12 sm:w-16 rounded border p-1 text-xs sm:text-sm"
            />
          </span>
          <select
            value={table.getState().pagination.pageSize}
            onChange={(e) => {
              table.setPageSize(Number(e.target.value));
            }}
            className="rounded border p-1 text-xs sm:text-sm"
          >
            {[6, 10, 20, 30, 40, 50].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                {t('show')} {pageSize}
              </option>
            ))}
          </select>
        </div>
      </div>
    </Card>
  );
}

export default CheckTable;